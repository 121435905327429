.rule-instances-list {

	details {
		> summary {
			opacity: 0.5;
			text-decoration: dashed;
			font-size: 12px;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}

			.icon {
				height: 14px;
			}
		}

		&[open] {
			> summary {
				.icon {
					transform: rotate(90deg);
				}
			}
		}
	}
}