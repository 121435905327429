* {
	//box-shadow: none !important;
}

#root {
	height: 100vh;
}

.layout {
	height: calc(100vh - 46px);
	border: 0 !important;
	margin: 0 !important;
	//box-shadow: none !important;
	overflow: hidden !important;
	//background-color: #0000000d !important;
}

.sidebar-left {
	max-width: 300px !important;
	min-width: 300px !important;
	background-color: white !important;
	display: flex;
	flex-direction: column;
}

.sidebar-right {
	max-width: 300px !important;
	min-width: 300px !important;
	background-color: white !important;

	&--lg {
		max-width: 480px !important;
		min-width: 480px !important;
	}
}

.sidebar-left,
.sidebar-right {
	> .ui.card,
	> .ps > .ui.card {
		//border: 0 !important;
		//box-shadow: none !important;
	}

	.ui.card > .content > .header:not(.ui),
	.ui.cards > .card > .content > .header:not(.ui) {
		//margin-top: 0;
	}
}

::-webkit-scrollbar {
	width: 6px !important;
	height: 6px !important;
}

::-webkit-scrollbar-track {
	background: transparent !important;
}

::-webkit-scrollbar-thumb {
	border: 3px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	border-radius: 9999px;
}

.ps__rail-x,
.ps__rail-y {
	z-index: 99;
}

code {
	font-size: 11px;
}

.header .close-btn {
	position: absolute;
	top: 15px;
	right: 2px;
	background: transparent;

	.icon {
		font-size: 0.75em;
	}
}

.waiting {
	pointer-events: none;
	opacity: 0.5;
}

.muted {
	pointer-events: none;
	position: relative;

	&:after {
		content: '';
		pointer-events: none;
		position: absolute;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.6);
	}
}

.app-header {
	background: #00b5ad;
	color: white;
	padding: 5px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 46px;

	a {
		color: white;
	}
}

.app-header__logo {
	display: flex;
	align-items: center;
	color: white;
}

.app-header__title {
	margin: 0 0 0 5px;
	font-family: 'Montserrat', sans-serif;
	font-size: 22px;
}

.ui.dimmer {
	background-color: rgba(0, 0, 0, .15);
}

.bg-white,
.ui.basic.button.bg-white {
	background: white !important;
}
.ui.basic.button.btn-share .icon:before {
	margin-left: -2px;
}

.scrollbar-container > pre {
	margin: 0;
}