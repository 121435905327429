.react-flow__node {
  box-shadow: none !important;
  border: 1px solid rgba(34, 36, 38, .25) !important;
  width: 129px !important;
  min-height: 49px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: white;
  border-radius: 5px !important;
  font-size: 12px;
}

.react-flow__node.selected {
  /*background-color: rgba(0, 255, 0, 0.3);*/
  border: 1px solid rgb(0, 181, 173) !important;
  outline: 2px solid rgb(0, 181, 173) !important;
}

.react-flow__node {
  &.debuggable {
    background-color: #d9f4f3 !important;
  }

  &-input,
  &.input,
  &-trigger,
  &.output {
    background-color: #d9f4f3 !important;

    &.selected {
      border: 1px solid rgb(0, 181, 173) !important;
      outline: 2px solid rgb(0, 181, 173) !important;
    }
  }

  &-trigger {
    font-size: 12px;
    padding: 10px;
  }

  &.error {
    background-color: rgba(255, 0, 0, 0.15) !important;

    &.selected {
      border: 1px solid #8f1616 !important;
      outline: 2px solid #8f1616 !important;
    }
  }

  &-scratchpad,
  &-instanceInfo,
  &-stickyNote {
    width: 400px !important;
    background-color: #fff6dd !important;
  }

  &-stickyNote {
    width: 200px !important;
  }
}

.react-flow__handle {
  width: 12px !important;
  height: 12px !important;
  background: white !important;
  border: 1px solid rgba(34, 36, 38, .25) !important;
}

.react-flow__handle-top {
  top: -6px !important;
}

.react-flow__handle-bottom {
  bottom: -6px !important;
}

.react-flow__edge-textbg {
  fill: rgb(242 242 242) !important;
}