.react-flow__edge {
  &.selected .react-flow__edge-path {
    stroke: #00b5ad !important;
    stroke-width: 3 !important;
  }
}

.react-flow__node {
  &.disabled {
    pointer-events: none !important;
    opacity: 0.25 !important;
    cursor: default !important;
  }
}

.react-flow.debug .react-flow__node {
  cursor: pointer !important;
}
