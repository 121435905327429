@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 1280px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

details > summary {
	list-style: none;

	&::marker {
		display: none;
	}
}